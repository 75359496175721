.GlitchBoard {
  color: white;
  margin-top: -15vh;
  .GlitchInput{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .ToggleButton{
      margin-right: 15px;
      width: 70px;
    }

    .InputKnob{
      margin-right: 11px;
    }
  }
}