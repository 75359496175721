.HomePage {
  height: 100vh;
  width: 100vw;
  background-color: $darker-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .HomePageMiddle {
    .LeftPanel{
      position: fixed;
      display: flex;
      flex-direction: column;
      height: 100%;
      left: 50px;
      top: 0px;
      align-items: center;
      justify-content: center;
    }

    .DragDrop {
      margin: 0 0 5vh 0;
    }
  
    .DragDropImageContainer {
      margin: 5vh 0 5vh 0;
    }
  }

  .MoshButtonMessage {
    font-size: 20px;
    margin-bottom: 0vh;
    margin-top: 0vh;
    color: #282c34;
    transition: margin-bottom 0.25s ease-in-out, margin-top 0.25s ease-in-out,
      color 0.15s ease-in-out;
    &.active {
      margin-bottom: -2vh;
      margin-top: 2vh;
      visibility: visible;
      color: #ba5a31;
      transition: margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
        color 0.25s ease-in-out;
    }
  }
}

