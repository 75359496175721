@charset 'UTF-8';

$hover-drop-shadow: 0.7vh;
$hover-margin-bottom: 0.8vh;
$hover-margin-top: 0.2vh;

$click-drop-shadow: 0.2vh;
$click-margin-bottom: 0.3vh;
$click-margin-top: 0.7vh;

$border-length: 5px;
$drop-shadow: 0.9vh;

$orange: #ba5a31;
$dark-orange: #683c2a;
$white: #ffffff;
$light-gray: #c5c6d0;
$gray: #a5a0b1;
$dark-gray: #373d49;
$darker-gray: #282c34;
$darkest-gray: #121418;

// Import Page Styles:
@import './HomePage';

// Import Component Styles:
@import './DragDrop';
@import './GlitchBoard';
@import './InputKnob';
@import './Buttons';

* {
  outline: 0;
  user-select: none;
  font-family: 'Droid Sans Mono', monospace;

  .hide {
    display: none;
  }
}

.pad-top {
  margin-top: 20px;
}
