.InputKnob{
  width: 68px;
  height: 68px;
  border-radius: 68px;
  background: radial-gradient(circle, $darker-gray 11%, $orange 12%);
  box-shadow: 0px $click-drop-shadow 0px $orange;
  border: solid $border-length $darker-gray;
  z-index: 1;
  margin-top: -8px;
  margin-bottom: 8px;
  margin-left: -2px;

  &.disabled{
    background: radial-gradient(circle, $darker-gray 11%, $dark-orange 12%);
    box-shadow: 0px $click-drop-shadow 0px $dark-orange;
  }

  &.ready{
    cursor: pointer;
  }
}