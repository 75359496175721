.DragDrop {
  color: white;
  text-align: center;
  cursor: pointer;

  #GlitchedLogo{
    width: 300px;
  }

  #drag-message-top{
    margin-bottom: 170px;
  }

  #drag-message-right{
    transform: rotate(90deg);
    margin-left: 350px;
    margin-bottom: -40px;
    width: fit-content;
  }

  #drag-message-bottom{
    transform: rotate(180deg);
  }

  #drag-message-left{
    transform: rotate(270deg);
    margin-bottom: -170px;
    width: fit-content;
  }
}

.DragDropImageContainer{
  display: flex;
}

.DragDropImage{
  width: 50vw;
  max-width: 50vw;
  min-width: 50vw;
  margin: auto;
  border-radius: 10px;
  border: 10px solid $orange;
  box-shadow: 15px 15px $dark-gray, -15px -15px $darkest-gray;
}