.ClickButton {
  color: $darker-gray;
  font-size: 5vh;
  outline: none;
  border: none;
  background-color: $orange;
  padding: 1vh 3vh;
  border-radius: 5vh;
  margin-bottom: 1vh;
  pointer-events: all !important;
  border-radius: 100px;
  border: solid $border-length $darker-gray;
  min-width: 16vw;
  padding: 1.4vh 1.7vh 1.4vh 1.7vh;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
    margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  box-shadow: 0px $drop-shadow 0px $orange;
  &:hover {
    cursor: pointer;
    box-shadow: 0px $hover-drop-shadow 0px $orange;
    margin-bottom: $hover-margin-bottom;
    margin-top: $hover-margin-top;
    &:active {
      transition: border 0s ease-in-out, color 0s ease-in-out,
        margin-top 0s ease-in-out, box-shadow 0s ease-in-out,
        margin-bottom 0s ease-in-out, margin-top 0s ease-in-out,
        background-color 0s ease-in-out;
      box-shadow: 0px $click-drop-shadow 0px $orange;
      margin-bottom: $click-margin-bottom;
      margin-top: $click-margin-top;
    }
  }

  &.active {
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
      margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    box-shadow: 0px $click-drop-shadow 0px $orange;
    margin-bottom: $click-margin-bottom;
    margin-top: $click-margin-top;
    &:focus {
      -webkit-tap-highlight-color: transparent !important;
      outline: none !important;
      touch-action: manipulation !important;
      touch-action: manipulation !important;
      -webkit-touch-callout: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
    }
  }

  &.inactive{
    background-color: $dark-orange;
    box-shadow: 0px $drop-shadow 0px $dark-orange;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
      margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: 0px $hover-drop-shadow 0px $orange;
      margin-bottom: $hover-margin-bottom;
      margin-top: $hover-margin-top;
    }
  }
}

.ToggleButton{
  color: $darker-gray;
  font-size: 5vh;
  outline: none;
  border: none;
  background-color: $dark-orange;
  padding: 1vh 3vh;
  border-radius: 5vh;
  margin-bottom: 1vh;
  pointer-events: all !important;
  border-radius: 100px;
  border: solid $border-length $darker-gray;
  padding: 1.4vh 1.7vh 1.4vh 1.7vh;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
    margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  box-shadow: 0px $drop-shadow 0px $dark-orange;
  &.ready{
    cursor: pointer;
    &:hover {
      box-shadow: 0px $hover-drop-shadow 0px $orange;
      margin-bottom: $hover-margin-bottom;
      margin-top: $hover-margin-top;
      &:active {
        transition: border 0s ease-in-out, color 0s ease-in-out,
          margin-top 0s ease-in-out, box-shadow 0s ease-in-out,
          margin-bottom 0s ease-in-out, margin-top 0s ease-in-out,
          background-color 0s ease-in-out;
        box-shadow: 0px $click-drop-shadow 0px $orange;
        margin-bottom: $click-margin-bottom;
        margin-top: $click-margin-top;
      }
    }
  }

  &.active {
    cursor: pointer;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
      margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
      background-color: $orange;
    box-shadow: 0px $click-drop-shadow 0px $orange;
    margin-bottom: $click-margin-bottom;
    margin-top: $click-margin-top;
    &:focus {
      -webkit-tap-highlight-color: transparent !important;
      outline: none !important;
      touch-action: manipulation !important;
      touch-action: manipulation !important;
      -webkit-touch-callout: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
      user-select: none !important;
    }
  }

  &.inactive{
    background-color: $dark-orange;
    box-shadow: 0px $drop-shadow 0px $dark-orange;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out,
      margin-top 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      margin-bottom 0.15s ease-in-out, margin-top 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
  }
}